import React from 'react';
import './App.scss';
import Navbar from './components/Navbar';
import {Box, Button, Container, Grid, Typography} from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Link from '@mui/material/Link';
import GitHubIcon from '@mui/icons-material/GitHub';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function App() {
  const services = [
    {
      title: 'Mobile App Development',
      description: 'Turn your unique app ideas into reality with our comprehensive mobile app development services. Our skilled developers use the latest technologies to create intuitive, engaging, and feature-rich applications tailored to your specific needs.'
    },
    {
      title: 'Website Development',
      description: 'Your website is often the first interaction a potential customer has with your business. Our experienced web development team crafts visually appealing, user-friendly, and highly functional websites. From static pages to dynamic e-commerce platforms, we cover it all.'
    },
    {
      title: 'Cloud Technologies',
      description: 'Leverage the power of cloud computing to enhance scalability, security, and efficiency for your business operations. Our cloud experts help you navigate through various cloud platforms, ensuring seamless integration and optimal utilization of cloud resources.'
    }
  ];


  return (
    <Container maxWidth={'lg'} className="App">
      <Box paddingX={2}>
        <Navbar/>
      </Box>
      <Box paddingY={8} paddingX={0}>
        <Grid container spacing={2}>
          {/*<Grid item xs={12} sm={6}/>*/}
          <Grid item xs={12} sm={6}>
            <Typography align={'left'} variant="h4" fontWeight={'bold'}>
              Your Vision, Our Innovation
            </Typography>
            <Typography align={'left'}>
              Elevate your tech journey with Garg Innovations. Empowering businesses through web, mobile, cloud, and IT
              expertise.
            </Typography>
            <Box paddingY={4}>
              <Button onClick={() => {
                window.open('mailto:hello@garginnovations.com');
              }} size={'large'} variant={'contained'} style={{
                background: '#000', color: '#fff'
              }}>Get Started</Button>
            </Box>
          </Grid>
          {/*<Grid item xs={12} sm={6}/>*/}
        </Grid>
      </Box>

      <Box paddingY={4} paddingX={0}>
        <Typography margin={0} marginBottom={2} align={'center'} variant={'h4'}>
          Our Services
        </Typography>
        <Grid spacing={4} container>
          {
            services.map((service, index) => (
              <Grid item key={index} xs={12} sm={6} md={4}>
                <Typography textAlign={'justify'} marginBottom={1} variant={'h5'} fontWeight={'bold'}>
                  {service.title}
                </Typography>
                <Typography textAlign={'justify'}>
                  {service.description}
                </Typography>
              </Grid>
            ))
          }
        </Grid>
      </Box>

      <Grid container paddingY={4} spacing={2}>
        <Grid item>
          <Link href={'https://www.linkedin.com/company/garginnovations'} style={{
            color: '#000'
          }}>
            <LinkedInIcon fontSize={'large'}/>
          </Link>
        </Grid>
        <Grid item>
          <Link href={'https://github.com/garginnovations'} style={{
            color: '#000'
          }}>
            <GitHubIcon fontSize={'large'}/>
          </Link>
        </Grid>
        <Grid item>
          <Link href={'https://www.instagram.com/garginnovations'} style={{
            color: '#000'
          }}>
            <InstagramIcon fontSize={'large'}/>
          </Link>
        </Grid>
        <Grid item>
          <Link href={'https://www.facebook.com/garginnovations'} style={{
            color: '#000'
          }}>
            <FacebookIcon fontSize={'large'}/>
          </Link>
        </Grid>
        <Grid item>
          <Link href={'mailto:hello@garginnovations.com'} style={{
            color: '#000'
          }}>
            <EmailIcon fontSize={'large'}/>
          </Link>
        </Grid>
        <Grid item>
          <Link href={'https://wa.me/message/URL3WSGW6NZ2P1'} style={{
            color: '#000'
          }}>
            <WhatsAppIcon fontSize={'large'}/>
          </Link>
        </Grid>
      </Grid>
    </Container>
  );
}

export default App;
