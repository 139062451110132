import * as React from 'react';
import {Box, Typography} from '@mui/material';


export default function Navbar() {
  return (
    <Box component="nav" style={{
      background: '#FFF',
      color: '#000',
      paddingTop: 16
    }}>
      <Typography
        fontWeight={'bold'}
        variant="h4"
        align={'center'}
      >
        Garg Innovations
      </Typography>
    </Box>

  );
}
